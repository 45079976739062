import { fetchUtils } from "react-admin";
import { stringify } from "query-string";

const apiUrl = "https://casting-api.palms.plus/api";
// const apiUrl = process.env.REACT_APP_API_URL;
const httpClient = async (url, options = {}) => {
  const defaultOptions = {
    credentials: 'include', // Добавя cookies към заявките
  };
  return fetchUtils.fetchJson(url, { ...defaultOptions, ...options });
};

const dataProvider = {
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    try {
      const { headers, json } = await httpClient(url);
      return {
        data: json.map((item) => ({ ...item, id: item._id })),
        total: parseInt(headers.get("content-range").split("/").pop(), 10),
      };
    } catch (error) {
      console.error("Error fetching list:", error.message);
      throw error;
    }
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: { ...json.data, id: json.id },
    })),


  getMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    try {
      const { json } = await httpClient(url);
      return {
        data: json.map((item) => ({ ...item, id: item._id })),
      };
    } catch (error) {
      console.error("Error fetching many:", error.message);
      throw error;
    }
  },

  getManyReference: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    try {
      const { headers, json } = await httpClient(url);
      return {
        data: json.map((item) => ({ ...item, id: item._id })),
        total: parseInt(headers.get("content-range").split("/").pop(), 10),
      };
    } catch (error) {
      console.error("Error fetching many references:", error.message);
      throw error;
    }
  },

  update: async (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`;
    try {
      const { json } = await httpClient(url, {
        method: "PUT",
        body: JSON.stringify(params.data),
      });
      return {
        data: { ...json, id: json._id },
      };
    } catch (error) {
      console.error("Error updating resource:", error.message);
      throw error;
    }
  },

  updateMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    try {
      const { json } = await httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
        method: "PUT",
        body: JSON.stringify(params.data),
      });
      return {
        data: json.map((item) => ({ ...item, id: item._id })),
      };
    } catch (error) {
      console.error("Error updating many resources:", error.message);
      throw error;
    }
  },

  create: async (resource, params) => {
    const url = `${apiUrl}/${resource}/create`;
    try {
      const { json } = await httpClient(url, {
        method: "POST",
        body: JSON.stringify(params.data),
      });
      return {
        data: { ...params.data, id: json._id },
      };
    } catch (error) {
      console.error("Error creating resource:", error.message);
      throw error;
    }
  },

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response;
      })
      .then(({ json }) => ({
        ...json,
        id: json._id,
      }))
      .catch((error) => {
        console.error("Error deleting resource:", error.message);
        throw error;
      }),


  createMany: async (resource, params) => {
    const url = `${apiUrl}/${resource}/create`;
    try {
      const { json } = await httpClient(url, {
        method: "POST",
        body: JSON.stringify(params.data),
      });
      return {
        data: Array.isArray(json)
          ? json.map((item) => ({ ...item, id: item._id }))
          : [{ ...json, id: json._id }],
      };
    } catch (error) {
      console.error("Error creating many resources:", error.message);
      throw error;
    }
  },

  deleteMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    try {
      const { json } = await httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
        method: "DELETE",
        body: JSON.stringify(params.data),
      });
      return {
        data: json.map((item) => ({ ...item, id: item._id })),
      };
    } catch (error) {
      console.error("Error deleting many resources:", error.message);
      throw error;
    }
  },
};

export default dataProvider;
